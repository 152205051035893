<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action">
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.roleName.$error }">
                                <label for="roleName">Tên nhóm quyền</label>
                                <md-input name="roleName" v-model="entity.roleName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.roleName.required">Vui lòng nhập tên nhóm quyền</span>
                            </md-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import roleService from '../../../api/roleService';
import messageBox from '../../../utils/messageBox';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default({
    metaInfo: {
        title: 'Thêm/Cập nhật nhóm quyền',
    },
    data() {
        return {
            title: '',
            id: 0,
            submitted: false,
            entity: { id: 0, roleName: '' },
        }
    },
    created() {
        this.id = this.$route.params.id;
        if(this.id > 0){
            this.title = 'Cập nhật nhóm quyền';
            this.getById();
        }
        else{
            this.title = 'Thêm mới nhóm quyền';
        }
        this.getRoles();
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        submit(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.id > 0){
                this.edit();
            }
            else{
                this.add();
            }
        },

        add(){
            this.setLoading(true);
            roleService.add(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        edit(){
            this.setLoading(true);
            roleService.edit(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$router.push('/role');
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        getById(){
            this.setLoading(true);
            roleService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        back(){
            this.$router.push('/role');
        },
    },
    validations: {
        entity: {
            roleName: { required}
        }
    },
})
</script>
